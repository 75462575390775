import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode, Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ErrorSection from "../components/pages/NotFound/ErrorSection";
import { InitializeGA4, InitializeYA } from "../components/utils/Analytics";
import Loading from "../components/utils/Loading";
import AppRoutes from "./AppRoutes";

InitializeGA4();

const queryClient = new QueryClient();

const helmetContext = {};

const App = () => {
  useEffect(() => {}, []);

  return (
    <StrictMode>
      <InitializeYA />
      <ErrorBoundary fallback={ErrorSection}>
        <HelmetProvider context={helmetContext}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <AppRoutes />
              </Suspense>
            </BrowserRouter>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
