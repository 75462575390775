export const dataSite = {
  main: {
    address: "Москва, 123100, Пресненская наб. 12, Город Столиц",
    phone: "+7(925)995-87-90",
    phoneLink: "tel:+79259958790",
    email: "info@h4u.club",
    emailLink: "mailto:info@h4u.club",
    title: "h4u.club",
    subtitle: "h4u.club",
    description: "h4u - исследовательский проект",
    copyrightYear: "2023-2024",
    copyrightText: "h4u.club",
    copyrightDescription:
      "Все права защищены. Полное или частичное копирование материалов запрещено. Используем cookies для корректной работы сайта, персонализации пользователей и других целей, предусмотренных политикой обработки персональных данных.",
    wh: "Открыто: 24 часа / 7 дней",
    wh1: "Время работы офиса: 10:00 – 17:30",
    name: "ИП Ловков Александр Вячеславович",
    INN: "7801649294",
    KPP: "780101001",
  },
  design: {
    title: "LovkovAV.PRO",
    link: "https://lovkovav.pro/",
  },
  owner: {
    title: "123123",
  },
  countdown: {
    title: "h4u",
    subtitle: "А что такое счастье для тебя?",
    datetime: ["дней", "часов", "минут", "секунд"],
    description: "Вместе с вами разбираемся, что же такое счастье?",
  },
};
