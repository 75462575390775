import Social from "../components/social/Social";
import Logo from "../components/UI/Logo";
import { AppStoreDark } from "../components/UI/Svg";

export const dataFooter = {
  logo: <Logo />,
  title: "Приложение для мам и пап о колыбельных и не только",
  social: <Social />,
  name: "ООО «Простые изобретения»",
  rights: "Все права защищены © 2024",
  contactsTitle: "Контакты",
  contactsPhone: "+7(925)995-87-90",
  contactsSocial: "Telegram / WhatsApp / Viber",
  contactsLink: "Написать в Telegram",
  contactsEmail: "info@h4u.club",
  contactsAddress1: "Почтовый адрес",
  contactsDescription1: "123100, РФ, Москва, Пресненская наб. 12, Город Столиц",
  contactsAddress2: "Юридический адрес",
  contactsDescription2: "123100, РФ, Москва, Пресненская наб. 12, Город Столиц",
  download: "скачать приложение",
  link1: <AppStoreDark />,
  link2: <AppStoreDark />,
  policy: "Политика конфиденциальности",
  doc: "Пользовательское соглашение",
};
