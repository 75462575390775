import { FaTelegramPlane, FaInstagram, FaVk, FaWhatsapp } from 'react-icons/fa';

const Social = ({ light, data }) => {
  return (
    <div className={`social ${light ? 'social-light' : ''}`}>
      <ul className="social__list">
        <li className="social__list-item">
          <a href="social__list-link">
            <FaInstagram className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a href="social__list-link">
            <FaTelegramPlane className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a href="social__list-link">
            <FaVk className="social__list-icon" />
          </a>
        </li>
        <li className="social__list-item">
          <a href="social__list-link">
            <FaWhatsapp className="social__list-icon" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
