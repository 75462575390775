import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { Footer } from "../components/layout";
import { dataFooter } from "../data/dataFooter";

const AppLayout = () => {
  const location = useLocation();

  useEffect(() => {}, [location]);

  //

  return (
    <div className="layout-app">
      {/* <ScrollToTop top={500} />*/}
      {/*   <CookiePolicy />*/}
      {/*<Header />*/}
      <div className="layout-page">
        <Outlet />
      </div>
      <Footer data={dataFooter} />
    </div>
  );
};

export default AppLayout;
